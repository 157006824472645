/*
 * @Author: 黄孝娟
 * @Date: 2020-01-14 15:18:19
 * @LastEditors: 黄孝娟
 * @LastEditTime: 2020-01-14 15:39:19
 */
import axios from '@/lib/api.request'
// 客经报表列表 /abt/abtComKjLibw/getKJCount/{type}/{yearmon}
export const getKJCount = params =>
  axios.get('/abt/abtComKjLibw/getKJCount', {
    params
  })
