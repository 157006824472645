<template>
  <div class="content">
    <div class="title">
      <div class="t_date">
        <ul>
          <li @click="selectDate('1')" :class="select=='1'?'select':''"><span>年</span></li>
          <li @click="selectDate('2')" :class="select=='2'?'select':''"><span>月</span></li>
          <li @click="selectDate('3')" :class="select=='3'?'select':''"><span>日</span></li>
        </ul>
      </div>
      <div class="b_date">
        <div>
          <span>日期</span>
        </div>
        <div @click="showChangeDate">
          <i class="iconfont iconkaoqintongji" />
          <span>{{showyearmon}}</span>
          <md-icon name="arrow-down" size="md"></md-icon>
        </div>
      </div>
    </div>
    <div class="middle">
      <div class="item1">
        <span class="num">{{zfnum}}</span><br/>
        <span class="text">转发</span>
      </div>
      <div class="item1 item2">
        <span class="num">{{hknum}}</span><br/>
        <span class="text">获客</span>
      </div>
      <div class="item1">
        <span class="num">{{hynum}}</span><br/>
        <span class="text">活跃人数</span>
      </div>
    </div>
    <div class="charts" v-if="flag">
      <div class="c_title"><span>转发</span></div>
      <div id="intrinsicChart" :style="{width: '100%', height: '300px'}"></div>
    </div>
    <div class="charts" v-if="flag">
      <div class="c_title"><span>获客</span></div>
      <div id="forward" :style="{width: '100%', height: '300px'}"></div>
    </div>
    <div class="charts" v-if="flag">
      <div class="c_title"><span>活跃人数</span></div>
      <div id="activeNumber" :style="{width: '100%', height: '300px'}"></div>
    </div>
    <md-date-picker
      ref="datePicker"
      v-model="isShowData1"
      type="custom"
      title="选择日期"
      is-twelve-hours
      :custom-types="['yyyy']"
      :default-date="currentDate"
      @confirm="onDatePickerConfirm"
    ></md-date-picker>
    <md-date-picker
      ref="datePicker"
      v-model="isShowData2"
      type="custom"
      title="选择日期"
      is-twelve-hours
      :custom-types="['yyyy', 'MM']"
      :default-date="currentDate"
      @confirm="onDatePickerConfirm"
    ></md-date-picker>
    <md-date-picker
      ref="datePicker"
      v-model="isShowData3"
      type="custom"
      title="选择日期"
      is-twelve-hours
      :custom-types="['yyyy', 'MM','dd']"
      :default-date="currentDate"
      @confirm="onDatePickerConfirm"
    ></md-date-picker>
  </div>
</template>
<script>
import { getKJCount } from '@/api/smarkboard/kjreport/index'
export default {
  data () {
    return {
      currentDate: new Date(),
      isShowData: false,
      isShowData1: false,
      isShowData2: false,
      isShowData3: false,
      select: '1',
      aaaa: '2020-01-01',
      yearmon: '',
      showyearmon: '',
      hknum: '',
      hynum: '',
      zfnum: '',
      x_hkList: [],
      y_hkList: [],
      x_zfList: [],
      y_zfList: [],
      hyList: [],
      flag: true // true 显示 false 不显示
    }
  },
  created () {
    var d = new Date()
    var year = d.getFullYear()
    this.yearmon = `${year}`
    this.showyearmon = `${year}年`
    this.getData()
  },
  methods: {
    onDatePickerConfirm (columnsValue) {
      let aaa = []
      let bbb = []
      for (var i = 0; i < columnsValue.length; i++) {
        aaa.push(columnsValue[i].text)
        bbb.push(columnsValue[i].value)
      }
      this.showyearmon = aaa.join('')
      this.yearmon = bbb.join('-')
      this.getData()
    },
    selectDate (val) {
      this.select = val
      var d = new Date()
      var year = d.getFullYear()
      var month = (d.getMonth() + 1)
      var day = d.getDate()
      if (val == '1') {
        this.yearmon = `${year}`
        this.showyearmon = `${year}年`
      } else if (val == '2') {
        this.yearmon = `${year}-${month}`
        this.showyearmon = `${year}年${month}月`
      } else {
        this.yearmon = `${year}-${month}-${day}`
        this.showyearmon = `${year}年${month}月${day}日`
      }
      this.getData()
    },
    showChangeDate () {
      if (this.select == '1') {
        this.isShowData1 = true
      } else if (this.select == '2') {
        this.isShowData2 = true
      } else {
        this.isShowData3 = true
      }
    },
    getData () {
      this.x_zfList = []
      this.y_zfList = []
      this.x_hkList = []
      this.y_hkList = []
      this.hyList = []
      let data = {
        type: this.select,
        yearmon: this.yearmon
      }
      getKJCount(data).then(res => {
        let Data = res.data.data
        this.hknum = Data.hknum
        this.hynum = Data.hynum
        this.zfnum = Data.zfnum
        // true 显示 false 不显示
        this.flag = Data.flag
        for (var i = 0; i < Data.zf.length; i++) {
          this.x_zfList.push(Data.zf[i].tjname)
          this.y_zfList.push({ value: Data.zf[i].zfnum, name: Data.zf[i].tjname })
          this.hyList.push({ value: Data.zf[i].hynum, name: Data.zf[i].tjname })
        }
        for (var j = 0; j < Data.hk.length; j++) {
          this.x_hkList.push(Data.hk[j].tjname)
          this.y_hkList.push({ value: Data.hk[j].hknum, name: Data.hk[j].tjname })
        }
        this.drawLine()
      })
    },
    drawLine () {
      let intrinsicChart = this.$echarts.init(document.getElementById('intrinsicChart'))
      // 绘制转发图表
      intrinsicChart.setOption(
        {
          color: ['#C03235', '#9FD8FF', '#63A0A7', '#D28168', '#92C7AF'],
          tooltip: {
            trigger: 'item',
            formatter: '{a} <br/>{b}: {c} ({d}%)'
          },
          legend: {
            orient: 'vertical',
            left: 10,
            data: this.x_zfList
          },
          series: [
            {
              name: '访问来源',
              type: 'pie',
              radius: ['50%', '70%'],
              avoidLabelOverlap: false,
              label: {
                normal: {
                  show: false,
                  position: 'center'
                },
                emphasis: {
                  show: true,
                  textStyle: {
                    fontSize: '30',
                    fontWeight: 'bold'
                  }
                }
              },
              labelLine: {
                normal: {
                  show: false
                }
              },
              data: this.y_zfList
            }
          ]
        })
      let forward = this.$echarts.init(document.getElementById('forward'))
      // 绘制获客图表
      forward.setOption(
        {
          color: ['#C03235', '#9FD8FF', '#63A0A7', '#D28168', '#92C7AF'],
          tooltip: {
            trigger: 'item',
            formatter: '{a} <br/>{b}: {c} ({d}%)'
          },
          legend: {
            orient: 'vertical',
            x: 'left',
            data: this.x_hkList
          },
          series: [
            {
              name: '郑州',
              type: 'pie',
              radius: ['50%', '70%'],
              avoidLabelOverlap: false,
              label: {
                normal: {
                  show: false,
                  position: 'center'
                },
                emphasis: {
                  show: true,
                  textStyle: {
                    fontSize: '30',
                    fontWeight: 'bold'
                  }
                }
              },
              labelLine: {
                normal: {
                  show: false
                }
              },
              data: this.y_hkList
            }
          ]
        }
      )
      let activeNumber = this.$echarts.init(document.getElementById('activeNumber'))
      // 绘制转发图表
      activeNumber.setOption({
        // color: ['#C03235', '#9FD8FF', '#63A0A7', '#D28168', '#92C7AF'],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        // legend: {
        //   data: ['2011年', '2012年']
        // },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'value',
          boundaryGap: [0, 0.01]
        },
        yAxis: {
          type: 'category',
          data: this.x_zfList
        },
        series: [
          {
            type: 'bar',
            data: this.hyList
          }
        ]
      })
    }
  }
}
</script>
<style lang="stylus" scoped>
.content {
  height 100%;
  overflow-y: hidden;
  background-color #fff
  .title {
    border-bottom 30px solid #F5F6F8
    .t_date {
      border-bottom 1px solid #EEEEEE
      ul {
        width: 80%;
        overflow: hidden;
        margin: 60px auto;
        text-align center

        li {
          width: 33%;
          display: block;
          float: left;
          border 1px solid #EEEEEE
          padding-top 10px
          padding-bottom 10px
          span {
            font-size:35px;
            font-family:PingFang SC;
            font-weight:500;
            color:rgba(56,56,56,1);
          }
        }
        .select {
          border 1px solid #228ACE
          background-color #E6F2FC
        }
      }
    }
    .b_date {
      display flex
      justify-content space-between
      padding 30px 70px
      span {
        font-size:40px;
        font-family:PingFang SC;
        font-weight:500;
        color:rgba(142,142,144,1);
      }
    }
  }
  .middle {
    display flex
    justify-content space-between
    border-bottom 30px solid #F5F6F8
    .item1 {
      width 35%
      text-align center
      padding-top 50px
      padding-bottom 50px
      .num {
        font-size:45px;
        font-family:Bahnschrift;
        font-weight:400;
        color:rgba(68,68,68,1);
      }
      .text {
        font-size:30px;
        font-family:PingFang SC;
        font-weight:500;
        color:rgba(153,153,153,1);
      }
    }
    .item2 {
      width 30% !important
      border-left 1px solid #EEEEEE
      border-right 1px solid #EEEEEE
    }
  }
  .charts {
    border-bottom 30px solid #F5F6F8
    width: 100%
    .c_title {
      padding 30px 80px
      border-bottom 1px solid #EEEEEE
      span {
        font-size:48px;
        font-family:PingFang SC;
        font-weight:bold;
        color:rgba(61,61,61,1);
      }
    }
  }
}
</style>
